<template>
  <div class="icon-bg">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.5 6.5C9.5 5.67157 10.1716 5 11 5H20C20.8284 5 21.5 5.67157 21.5 6.5C21.5 7.32843 20.8284 8 20 8H11C10.1716 8 9.5 7.32843 9.5 6.5ZM9.5 11.5C9.5 10.6716 10.1716 10 11 10H20C20.8284 10 21.5 10.6716 21.5 11.5C21.5 12.3284 20.8284 13 20 13H11C10.1716 13 9.5 12.3284 9.5 11.5ZM11 15C10.1716 15 9.5 15.6716 9.5 16.5C9.5 17.3284 10.1716 18 11 18H20C20.8284 18 21.5 17.3284 21.5 16.5C21.5 15.6716 20.8284 15 20 15H11Z"
        fill="#A7A9C0"
      />
      <path
        opacity="0.3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 6.5C7.5 7.32843 6.82843 8 6 8C5.17157 8 4.5 7.32843 4.5 6.5C4.5 5.67157 5.17157 5 6 5C6.82843 5 7.5 5.67157 7.5 6.5ZM7.5 11.5C7.5 12.3284 6.82843 13 6 13C5.17157 13 4.5 12.3284 4.5 11.5C4.5 10.6716 5.17157 10 6 10C6.82843 10 7.5 10.6716 7.5 11.5ZM6 18C6.82843 18 7.5 17.3284 7.5 16.5C7.5 15.6716 6.82843 15 6 15C5.17157 15 4.5 15.6716 4.5 16.5C4.5 17.3284 5.17157 18 6 18Z"
        fill="#A7A9C0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconTask",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;

      svg {
        path {
          fill: #4f52f4;
        }
      }
    }
  }
  &:active {
    background: transparent;
    svg {
      rect {
        fill: #5551f9;
      }
    }
  }
}
</style>
